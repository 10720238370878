import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { DragDropDirective } from './drag-drop.directive';
import { NavbarComponent } from './navbar/navbar.component';
import { MainComponent } from './main/main.component';
import { LoadCampaignComponent } from './load-campaign/load-campaign.component';
import { DeleteCampaignComponent } from './delete-campaign/delete-campaign.component';
import { InformacionService } from './informacion.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';

@NgModule({
  declarations: [
    AppComponent,
    UploadFileComponent,
    DragDropDirective,
    NavbarComponent,
    MainComponent,
    LoadCampaignComponent,
    DeleteCampaignComponent,
    CreateCampaignComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [InformacionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
