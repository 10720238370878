import { Component, OnInit } from '@angular/core';
import { Menu } from '../../assets/json/menu';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  itemsMenu = Menu;
  currentUser = false;
  constructor() {}
  ngOnInit() {
  }
}
