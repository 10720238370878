export const Menu = [
  {
    title: 'Crear Campaña',
    icon: '../../../assets/img/icon-create-off.svg',
    iconActive: '../../../assets/img/icon-create.svg',
    nav: '/createcampaign'
  },
  {
    title: 'Cargar Campañas',
    icon: '../../../assets/img/icon-upload-off.svg',
    iconActive: '../../../assets/img/icon-upload.svg',
    nav: '/loadcampaign'
  },
  {
    title: 'Inactivar Campañas',
    icon: '../../../assets/img/icon-delete-off.svg',
    iconActive: '../../../assets/img/icon-delete.svg',
    nav: '/deletecampaign'
  },
  {
    title: 'Salir',
    icon: '../../../assets/img/exit-off.svg',
    iconActive: '../../../assets/img/exit.svg',
    nav: '/main'
  }
];
