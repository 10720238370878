import { Component, OnInit } from '@angular/core';
import { Products } from '../../assets/json/products';
import { Campaigns } from '../../assets/json/campaigns';
import { InformacionService } from '../informacion.service';
import { CampaignService } from '../campaign.service';
import { DisabledDto } from '../dtos/DisabledDto';
import { HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-delete-campaign',
  templateUrl: './delete-campaign.component.html',
  styleUrls: ['./delete-campaign.component.scss']
})
export class DeleteCampaignComponent implements OnInit {

  itemsProducts = Products;
  itemsCampaigns = Campaigns;
  productSelect = '';
  campaignSelect = 'FLDD_';
  cedulasArray: any = [];
  cedulas = '';
  reportDetail: any = [];
  loading = false;
  delete = false;
  del = false;
  delVar = false;

  constructor(
    private informacionService: InformacionService,
    private campaignService: CampaignService) {
  }

  ngOnInit() {
  }

  deleteCampaign() {
    this.cedulasArray = this.informacionService.cedulasInactivar;
    this.disabledCampaigns();
    // console.log(this.cedulasArray.length);
    // for(let i=0;i<this.cedulasArray.length;i++){
    //   let aux = "'" + this.cedulasArray[i].toString() + "'";
    //   if(i===this.cedulasArray.length-1){
    //     this.cedulas = this.cedulas.concat(aux);
    //   }else{
    //     this.cedulas = this.cedulas.concat(aux, ",");
    //   }
    // }
    // console.log(this.cedulas);


  }


  disabledCampaigns() {
    // obtener las cedulas
    // obtener el prod y la campaña

    // armar objeto
    if (this.cedulasArray[0] !== undefined) {
      const data = new DisabledDto();
      data.product = this.productSelect;
      data.campaign = this.campaignSelect;
      data.customers = this.cedulasArray;
      this.loading = true;
      this.del = false;

      this.campaignService.disabledCampaigns(data).subscribe({
        next: (response: any) => {
        // console.log("Entre a DisabledCampaigns de CampaignServices");
        console.log('Imprimo response: ' + response);
        this.loading = false;
        this.reportDetail = response;
        this.del = true;
        this.delVar = true;
        },
        error: (err: any) => {
          // console.log("No entré al método del servicio");
          console.log('Imprimo error' + err);
          this.loading = false;
          this.reportDetail = err;
          this.del = true;
          this.delVar = false;
        },
        complete: () => {
          console.log('Completado');
        }
      }).add(() => {
        // Called when operation is complete (both success and error)
      });
    } else {
      alert('Archivo(s) no válido(s). Ingrese archivo(s) válido(s) para inactivar campañas');
      // window.location.reload();
      this.informacionService.cedulasInactivar = [];
    }
  }
}
