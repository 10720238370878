export const Products = [
    {
        title: 'Cupo_Aprob_LD',
        name: 'Cupo_Aprob_LD'
    },
    {
        title: 'Cupo_Aprob_TC',
        name: 'Cupo_Aprob_TC'
    },
    {
        title: 'Cupo_Aprob_LBR',
        name: 'Cupo_Aprob_LBR'
    }
];
