import { Component, OnInit } from '@angular/core';
import { Products } from '../../assets/json/products';
import { InformacionService } from '../informacion.service';
import { CampaignService } from '../campaign.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-load-campaign',
  templateUrl: './load-campaign.component.html',
  styleUrls: ['./load-campaign.component.scss']
})
export class LoadCampaignComponent implements OnInit {

  itemsProducts = Products;
  productSelect = '';
  campaignSelect = '';
  files: any = [];
  reportDetail: any = [];
  respuesta = '';
  loading = false;
  upl = false;
  uplVar = false;

  upload = true;

  constructor(
    private informacionService: InformacionService,
    private campaignService: CampaignService) { }

  ngOnInit() {
  }

  async uploadFileAWS() {
    this.loading = true;
    this.upl = false;
    this.uploadFileAux().then(() => {
      this.campaignService.executeUploadCampaigns(this.productSelect);
    }, () => {
      console.log('No funcionó el cargue de archivos a AWS ');
      this.loading = false;
      this.upl = true;
      this.uplVar = false;
    });
  }

  uploadFileAux() {
    return new Promise((resolve, rejected) => {
      this.files = this.informacionService.cedulasCargar;
      const numArchivos = this.files.length;
      for (let a = 0; a < numArchivos; a++) {
        const file = this.files[a];
        this.campaignService.uploadCampaigns(file).subscribe({
          next: (response: any) => {
            if (a === numArchivos - 1) {
              return resolve();
            }
            console.log('Entre a LoadCampaigns de CampaignServices');
            console.log('Imprimo response: ' + response);
            this.loading = false;
            this.reportDetail = response;
            this.upl = true;
            this.uplVar = true;
          },
          error: (err: any) => {
            console.log('No entré al método del servicio');
            console.log('Imprimo error' + err);
            this.loading = false;
            this.reportDetail = err;
            this.upl = true;
            this.uplVar = false;
          },
          complete: () => {
            this.loading = false;
            this.upl = true;
            this.uplVar = true;
            console.log('Completado');
          }
        }).add(() => {
          // Called when operation is complete (both success and error)
        });
      }
    });
  }
}
