import { Injectable } from '@angular/core';
import { DisabledDto } from '../app/dtos/DisabledDto';
import { CreateDto } from './dtos/CreateDto';
import {environment} from '../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

/**
 * Servicio enrcargado de realiza la logica de negocio de la aplicacion
 */
@Injectable({
  providedIn: 'root'
})
export class CampaignService  {

  constructor(private httpClient: HttpClient) { }

  FOLDER = 'prueba/';
  url = '';


  /**
   * Se encarga de crear la campaña
   * enviada por parametro
   */
  createCampaigns(data: CreateDto) {
    // 'http://localhost:9506/campaign-admin/campaigns-create'
    return this.httpClient.post(environment.createCampaigns,
      data);
  }


  /**
   * Se encarga de inactivar las campañas
   * enviadas por parametro
   */
  public disabledCampaigns(data: DisabledDto) {
    // const head = new HttpHeaders({ 'identification-number': '1033738937',
    //                                 'access-token': 'M0eclpQnMbrDjtOuH8t3YvyxgjvT9omwkc9ogRFIF0BBvX3XSjrI5BLn9K2QFlvV',
    //                                 'request-id': '06c75236-9982-4d21-8443-bc1a67c94e9e',
    //                                 'Access-Control-Allow-Origin': '*'});

    return this.httpClient.post(environment.disabledCampaigns,
                                  data);
  }


  /**
   * Se encarga de cargar las campañas enviadas por parametro
   */
  public uploadCampaigns(file) {
    return this.httpClient.post(environment.uploadCampaigns, file, {headers :
                                new HttpHeaders({ 'Content-Type': 'application/xlsx' })} );
  }


  public executeUploadCampaigns(product) {
    this.url = environment.executeUploadCampaigns + product;
    // console.log('Soy la URL: ' + this.url);
    this.httpClient.get(this.url).subscribe((val) => {
      // console.log(val);
    });
    return false;
  }
}
