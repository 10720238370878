import { Component, OnInit } from '@angular/core';
import { Products } from '../../assets/json/products';
import { CampaignService } from '../campaign.service';
import { CreateDto } from '../dtos/CreateDto';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit {

  itemsProducts = Products;
  productSelect = '';
  campaignSelect = 'FLDD_';
  description = '';
  reportDetail: any = [];
  loading = null;
  create = null;
  cre = null;
  creVar = null;

  constructor(private campaignService: CampaignService) { }

  ngOnInit() {
  }

  createCampaign() {
      const data = new CreateDto();
      data.product = this.productSelect;
      data.campaign = this.campaignSelect;
      data.description = this.description;
      this.loading = true;
      this.cre = false;
      this.campaignService.createCampaigns(data).subscribe({
        next: (response: any) => {
        // console.log("Entre a CreateCampaigns de CampaignServices");
        // console.log('Imprimo response: ' + response);
        this.loading = false;
        this.reportDetail = response;
        this.cre = true;
        this.creVar = true;
        },
        error: (err: any) => {
          // console.log("No entré al método del servicio");
          // console.log('Imprimo error' + err);
          this.loading = false;
          this.reportDetail = err;
          this.cre = true;
          this.creVar = false;
        },
        complete: () => {
          // console.log('Completado');
        }
      }).add(() => {
        // Called when operation is complete (both success and error)
      });
  }
}
