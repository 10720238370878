import { Component, Input, ɵConsole, Output, EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx';
import { Informacion } from '../informacion.model';
import { InformacionService } from '../informacion.service';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  willDownload = false;
  @Input() upload = true;
  @Output() deleteCampanaOut = new EventEmitter<void>();
  @Output() loadCampanaOut = new EventEmitter<void>();
  names: any = [];
  files: any = [];
  filesJSON: any = [];
  cedulasArray: any = [];


  // idNumberArray: any = [];
  // idTypeArray: any = [];
  // amountArray: any = [];
  // termArray: any = [];
  // rateArray: any = [];
  // emailArray: any = [];
  // cellPhoneArray: any = [];
  // codeTypeCampaignArray: any = [];
  // applicationDateArray: any = [];
  // endCampaignDateArray: any = [];
  // productArray: any = [];

  constructor(private informacionService: InformacionService) { }

  uploadFile(event) {
    // console.log(event);
    let bool = false;
    for (const index of event) {
      const element = index;
      for (const name of this.names ) {
        if (element.name === name) {
          bool = true;
        }
      }
      if (!bool) {
        this.names.push(element.name);
        this.files.push(element);
      }
    }
  }
  deleteAttachment(index) {
    if (this.files.length > 1) {
      this.files.splice(index, 1);
      this.names.splice(index, 1);
      this.filesJSON.splice(index, 1);
      this.cedulasArray = [];
    } else {
      this.files = [];
      this.names = [];
      this.filesJSON = [];
      this.cedulasArray = [];
    }
  }

  onFileChange() {
    if (this.upload) {

      const informacion1 = new Informacion(this.files, 'Load');
      this.informacionService.agregarInformacion(informacion1);
      this.files = [];
      this.names = [];
      this.filesJSON = [];
      this.cedulasArray = [];
      this.loadCampanaOut.emit();

      // (<HTMLInputElement> document.getElementById("btnCargar")).disabled = true;
      // this.readFiles().then(() => {

      // console.log("idNumberArray");
      // console.log(this.idNumberArray);

      // console.log("idTypeArray");
      // console.log(this.idTypeArray);
      // console.log("amountArray");
      // console.log(this.amountArray);

      // console.log("termArray");
      // console.log(this.termArray);
      // console.log("rateArray");

      // console.log(this.rateArray);

      // console.log("emailArray");
      // console.log(this.emailArray);

      // console.log("cellPhoneArray");
      // console.log(this.cellPhoneArray);

      // console.log("codeTypeCampaignArray");
      // console.log(this.codeTypeCampaignArray);

      // console.log("applicationDateArray");
      // console.log(this.applicationDateArray);

      // console.log("endCampaignDateArray");
      // console.log(this.endCampaignDateArray);

      // console.log("productArray");
      // console.log(this.productArray);

      // const informacion1 = new Informacion(this.cedulasArray, 'Delete');
      // this.informacionService.agregarInformacion(informacion1);
      // this.filesJSON = [];
      // this.cedulasArray = [];
      //   // this.deleteCampanaOut.emit();
      // }, () => {
      //   console.log('No funcionó el cargue de archivos');
      // });

    } else {
      // (<HTMLInputElement> document.getElementById("btnInactivar")).disabled = true;
      this.readFiles().then(() => {
        const informacion1 = new Informacion(this.cedulasArray, 'Delete');
        this.informacionService.agregarInformacion(informacion1);
        this.filesJSON = [];
        this.cedulasArray = [];
        this.files = [];
        this.names = [];
        this.deleteCampanaOut.emit();
      }, () => {
        console.log('No funcionó el cargue de archivos');
      });
    }
  }

  readFiles() {
    return new Promise((resolve, rejected) => {
      const numArchivos = this.files.length;
      // console.log("Cantidad de archivos: " + numArchivos);
      for (let a = 0; a < numArchivos; a++) {
        const file = this.files[a];
        this.loadFiles(file).then(() => {
          // console.log("Entré");
          this.loadFilesOneByOne(a).then(() => {
            if (a === numArchivos - 1) {
              return resolve();
            }
          }, () => {
            console.log('No funcionó el cargue de datos del archivo');
          });
        }, () => {
          console.log('No funcionó la lectura de archivos');
        });
      }
    });
  }

  loadFiles(file): Promise<any> {
    return new Promise((resolve, rejected) => {
      let workBook = null;
      const reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workBook.SheetNames[0];
        const worksheet = workBook.Sheets[firstSheet];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        const dataString = JSON.stringify(jsonData);
        this.filesJSON.push(dataString.slice(0, 1000000)); // Agrega a una lista la información leída del Excel
        // console.log("Archivos en filesJSON: " + this.filesJSON.length);
        return resolve();
      };
      reader.readAsBinaryString(file);
    });
  }

  loadFilesOneByOne(i): Promise<any> {
    // return new Promise((resolve, rejected) => {
    //   const dataArray = JSON.parse(this.filesJSON[i]); // Conversión del archivo leido a formato JSON
    //   console.log(dataArray);
    //   this.readFilesLoadCampaigns(dataArray);
    //   return resolve();
    // });
    return new Promise((resolve, rejected) => {
      const dataArray = JSON.parse(this.filesJSON[i]); // Conversión del archivo leido a formato JSON
      // console.log(dataArray);
      this.readFilesDisabledCampaigns(dataArray);
      return resolve();
    });
  }

  readFilesDisabledCampaigns(jsonObj) {
    const cedulas = jsonObj;
    // console.log("cedulas: " + cedulas);
    for (const b of cedulas) {
      this.cedulasArray.push(b.cedulas);
      // console.log("Soy cedula: " + cedulas[b].cedulas);
    }
  }

  // readFilesLoadCampaigns(jsonObj){
  //   const filas = jsonObj;
  //   console.log("filas: " + filas);
  //   for (const b of filas) {
  //     let date = b.application_date;
  //     let auxFecha = this.dateCalculate(date);
  //     let dateEnd = b.end_campaign_date;
  //     let auxFechaEnd = this.dateCalculate(dateEnd);

  //     // console.log ("Soy auxFecha: " + auxFecha);
  //     // console.log ("Soy auxFechaEnd: " + auxFechaEnd);
  //     // console.log("Soy identification_number: " + b.identification_number);
  //     // console.log("Soy identification_type: " + b.identification_type);
  //     // console.log("Soy amount: " + b.amount);
  //     // console.log("Soy term: " + b.term);
  //     // console.log("Soy rate: " + b.rate);
  //     // console.log("Soy email: " + b.email);
  //     // console.log("Soy cell_phone: " + b.cell_phone);
  //     // console.log("Soy code_type_of_campaign: " + b.code_type_of_campaign);
  //     // console.log(auxFecha);
  //     // console.log(auxFechaEnd);
  //     // console.log("Soy application_date: " + b.application_date);
  //     // console.log("Soy end_campaign_date: " + b.end_campaign_date);
  //     // console.log("Soy product: " + b.product);


  //     this.idNumberArray.push(b.identification_number);
  //     this.idTypeArray.push(b.identification_type);
  //     this.amountArray.push(b.amount);
  //     this.termArray.push(b.term);
  //     this.rateArray.push(b.rate);
  //     this.emailArray.push(b.email);
  //     this.cellPhoneArray.push(b.cell_phone);
  //     this.codeTypeCampaignArray.push(b.code_type_of_campaign);
  //     this.applicationDateArray.push(auxFecha);
  //     this.endCampaignDateArray.push(auxFechaEnd);
  //     this.productArray.push(b.product);

  //   }
  // }

  // dateCalculate(date){
  //   // console.log("Soy Date: " + date);
  //   let fecha = new Date ('12-30-1899');
  //   fecha.setDate(fecha.getDate() + date);
  //   let day = fecha.getDate();
  //   let dayAux = day.toString();
  //   let month = fecha.getMonth()+1;
  //   let monthAux = month.toString();
  //   // console.log("Soy el mes: " + monthAux);
  //   let year = fecha.getFullYear();
  //   let auxFecha = '';
  //   if(dayAux.length > 1 && monthAux.length >1){
  //     auxFecha = year+"-"+month+"-"+day;
  //   }else{
  //     if(dayAux.length === 1 && monthAux.length === 1 && month != 10){
  //       auxFecha = year+"-"+0+month+"-"+0+day;
  //     }else{
  //       if(dayAux.length === 1){
  //         auxFecha = year+"-"+month+"-"+0+day;
  //       }
  //       if(monthAux.length === 1 && month != 10){
  //         auxFecha = year+"-"+0+month+"-"+day;
  //       }
  //     }
  //   }
  //   return auxFecha;
  // }

}
