import { Injectable } from '@angular/core';
import { Informacion } from './informacion.model';

@Injectable({
  providedIn: 'root'
})
export class InformacionService {

  cedulasInactivar: any = [];
  cedulasCargar: any = [];

  constructor() { }

  agregarInformacion(informacion: Informacion) {
    if (informacion.tipo === 'Delete') {
        this.cedulasInactivar = informacion.arreglo;
    }
    if (informacion.tipo === 'Load') {
        this.cedulasCargar = informacion.arreglo;
    }
  }


}
