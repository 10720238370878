import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';
import { LoadCampaignComponent } from './load-campaign/load-campaign.component';
import { DeleteCampaignComponent } from './delete-campaign/delete-campaign.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: 'loadcampaign',
    component: LoadCampaignComponent
  },
  {
    path: 'deletecampaign',
    component: DeleteCampaignComponent
  },
  {
    path: 'createcampaign',
    component: CreateCampaignComponent
  },
  {
    path: '**',
    component: MainComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
