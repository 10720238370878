export const Campaigns = [
    {
        title: 'FLDD_01',
        name: 'FLDD_01'
    },
    {
        title: 'FLDD_21',
        name: 'FLDD_21'
    },
    {
        title: 'FLDD_22',
        name: 'FLDD_22'
    }
];
